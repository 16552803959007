<template>
    <div class="x-page-container" ref="resize">
        <div class="x-page-search">
            <x-search-item label="商品名称">
                <el-input v-model="search.shangPinMC" size="small"/>
            </x-search-item>
            <x-search-item label="商品货号" v-show="openHs">
                <el-input v-model="search.shangPinHuoHao" size="small"/>
            </x-search-item>
            <x-search-item label="商品条码" v-show="openHs">
                <el-input v-model="search.guiGeTiaoMa" size="small"/>
            </x-search-item>
            <x-search-item label="商品类别" v-show="openHs">
                <el-input v-model="search.shangPinLeiBie" size="small"/>
            </x-search-item>
            <x-search-item label="商品ID" v-show="openHs">
                <el-input v-model="search.shangHuShangPinId" size="small"/>
            </x-search-item>
            <x-search-item label="库存状态" v-show="openHs">
                <el-input v-model="search.kuCunZT" size="small"/>
            </x-search-item>
            <x-search-item label="上下架状态" v-show="openHs">
                <el-input v-model="search.shangXiaJiaZT" size="small"/>
            </x-search-item>
            <x-search-item label="供货商" v-show="false">
                <el-input v-model="search.gongHuoShangMC" size="small"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <!--<el-button type="primary" size="mini" @click="handleClear">清空</el-button>-->
                <el-button type="primary" size="mini" @click="openHs = !openHs">{{ openHs?'收起':'高级查询' }}</el-button>
                <el-button v-if="selection" type="primary" size="mini" @click="handleConfirms">确认选择</el-button>
            </div>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column v-if="selection" type="selection" width="40"/>
            <el-table-column v-if="!selection" width="100" align="center" label="操作">
                <el-button slot-scope="{row}" type="success" size="mini" round @click="handleConfirm(row)">确认
                </el-button>
            </el-table-column>
            <el-table-column width="180" prop="shangPinMC" label="商品名称"/>
            <el-table-column width="160" prop="shangPinDW" label="商品单位"/>
            <el-table-column width="160" prop="gongHuoShangMC" label="供货商"/>
            <el-table-column width="180" prop="shangPinPinPaiMC" label="商品品牌"/>
            <el-table-column width="100" prop="shangPinJinJia" label="商品进价">
                <template slot-scope="scope">
                    <span>{{scope.row.shiFuDuoGuiGe=="true"?scope.row.guiGeShangPinJinJia:scope.row.shangPinJinJia}}</span>
                </template>
            </el-table-column>
            <!--            <el-table-column width="100" prop="guiShuCangKu" label="归属仓库"/>-->
            <el-table-column width="120" prop="shangPinHuoHao" label="货号">
                <template slot-scope="scope">
                    <span>{{scope.row.shiFuDuoGuiGe=="true"?scope.row.guiGeHuoHao:scope.row.shangPinHuoHao}}</span>
                </template>
            </el-table-column>
            <el-table-column width="100" prop="shangPinTiaoMa" label="条码">
                <template slot-scope="scope">
                    <span>{{scope.row.shiFuDuoGuiGe=="true"?scope.row.guiGeTiaoMa:scope.row.shangPinTiaoMa}}</span>
                </template>
            </el-table-column>
            <el-table-column width="100" prop="fenLeiMC" label="商品分类"/>
            <el-table-column width="100" prop="ciJiGuiGeMC" label="规格名称"/>
            <el-table-column width="100" prop="dianNeiLSJ" label="店内零售价">
                <template slot-scope="scope">
                    <span>{{scope.row.shiFuDuoGuiGe=="true"?scope.row.guiGeShangPinLSJ:scope.row.shangPinJinJia}}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column width="100" prop="shangPinDW" label="单位">-->
            <!--                <XJiHuaDH slot-scope="{row}" :dm="row.kuCunZT"/>-->
            <el-table-column width="120" prop="beiZhu" label="备注"/>
        </el-table>

        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       :current-page.sync="searchNoClear.current"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
    </div>
</template>
<script>
    import {listAllGuiGe} from "@/service/spgl/ShangPinXX";
    import XTableSelector from "@/components/x/XTableSelector";
    import XJiHuaDH from "@/components/x/XJiHuaDH";

    export default {
        name: "ShangPinXXAllGuiGeSelector",
        mixins: [XTableSelector],
        components: {XJiHuaDH},
        props: {
            param: {
                gongHuoShang: "",
                shangPinLaiYuan: "",
                type: Boolean,
                default: false
            }
        },
        data() {
            this.refreshService = listAllGuiGe;
            return {
                search: {
                    shangPinMC: '',
                    shangPinDW: '',
                    gongHuoShangMC: this.param.gongHuoShangMC,
                    shangPinPinPaiMC: '',
                    shangPinJinJia: '',
                    shangPinHuoHao: '',
                    shangPinTiaoMa: '',
                    shangPinLaiYuan: this.param.shangPinLaiYuan,
                    fenLeiMC: '',
                    dianNeiLSJ: ''
                }
            }
        },
        methods: {
            handleClear() { //默认清空方法
                Object.keys(this.search).forEach((key) => this.search[key] = '')
                this.form.gongHuoShangMC

            },
        }
    }
</script>

<style scoped>
</style>